
// Override default variables before the import
$indigo: #472EB4;
$purple: #7B48C3;
$pink: #FF0097;


$primary: $pink; 
$link-color:    $pink;

$font-family-sans-serif: "Poppins", Roboto, "Helvetica Neue", Arial, sans-serif;
$btn-border-radius: 20rem;
$input-placeholder-color: #808080;
$navbar-dark-color: rgba(#fff, .75);
$navbar-dark-hover-color: rgba(#fff, .95);
$navbar-dark-active-color:#fff;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import url(https://fonts.googleapis.com/css?family=Poppins:400,300,700);

.full-height{
    height: 100vh;
}
html, body{
    font-family: "Poppins", Roboto, "Helvetica Neue", Arial, sans-serif !important;
    max-height: 100vh !important;
    margin: 0 !important;
    padding: 0 !important;
}

h1, h2, h3 {
    font-family: 'Poppins', sans-serif;
}

#root{
    height:100vh;
}
.topNav{
    height: 50px;
    width: 100%;
    z-index: 5;
    background-image: linear-gradient(to right, #472EB4 , #D903FF);
    margin: 0px;
}
.navbar-expand-lg .navbar-nav .dropdown-menu{
    left: auto;
    right:0;
}
.navbar-nav.mobile-nav{
    background-color:rgba(#fff, .15);
    padding-left: 2.5rem;
    a{
        color:rgba(#fff, .85);
    }
    .dropdown-menu{
        background-color:transparent;
        border:none;
        a.active{
            color:rgba(#fff, .85);
        }
        a:hover{
            background-color:rgba(#fff, .25);
        }
    }
}

.empty-state-header {
    font-size: 3.3vw !important;
    font-weight: bold;
    line-height: 1.2;
    margin-top: 1.0vw !important;
    margin-bottom: 0.3vw !important;
}
.empty-state-text {
    font-size: 1wv;
}

.cause-step{
    height: 80%;
    .text-side{
        width: 100%;
        margin: 0px;
        padding: 0px;
        p{
            margin-top: 0.3rem;
            margin-bottom: 1.5rem;
            font-size: 1.2rem;

        }
    }
    .img-side{
        text-align: center;
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        .cause-pic{
            width: 80%;
            height: auto;
            
        }
    }
    
}

.section-title{
    margin: 2.2rem auto;
    width: 90%;
}
.cause-list{
    width:90%;
    margin:auto;
}

.cause-card{
    width:21rem;
    height: 100%;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 5px #ebe8e8;
    cursor: pointer;
    .card-header{
        padding: 0;
        a, a:hover{
            color:white;
            text-decoration: none;
            height: 100%;
            width: 100%;
            padding: 12px 20px;

            .cause-top{
                font-weight: 300;
                font-size:1em;
                padding: 0 20px;

            }
        }
       
    }
    .published{
        background-color: $info;
    }
    .unpublished{
        background-color: #FFFFFF;
        color: #000000;
    }
    .card-footer{
       cursor:default;
    }
}

.new-campaign-button {
    width: 74.5%;
    align-items: right;
    text-align: right;
}

.empty-cause-card {
    border-radius: 10px 10px 0px 0px;
    width:21rem;
    background-color: #C4C4C4;
    height: 240px;
}

.button-size-card-container{
    width:30%;
    height: 50vh;
    min-height: 20%;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 5px #ebe8e8;
    margin: 25px;
}
.button-size-card {
    border-radius: 10px;
    width:100%;
    background-color: #FFFFFF;
    height: 100%;
    align-items:center;
    display: block;
}

.button-size-card-selected {
    border-radius: 10px;
    width:100%;
    background-color: #FFFFFF;
    height: 100%;
    align-items:center;
    display: block;
    border-color: navy;
    border-style: solid;
}
.button-size-image {
    margin: -5%;
}
.button-size-image-no-campaign {
    margin: 5%;
}
.button-size-card-title {
    font-weight:bold;
    width: 100%;
    text-align:left;
    margin: 10px;
}
.button-card-contents {
    width: 90%;
  height: 100%;
  margin: 0 auto;
  padding: 10px;
  position: relative;
}

.button-size-card-text {
    width: 98%;
    height:auto;
    text-align:left;
    margin: 10px;
    font-size:0.9vw;
}


// loading
.loading{
    text-align: center;
    margin-top:10rem;
    img{
        padding-top: 2rem;
    }
}

.design-detail{
    width: 80%;
    height: 100%;
    margin: 2rem auto;
    

    .title-row{
        margin: 2rem 0;
        border-bottom: lightgrey 1px solid;
        padding: 2rem 0;

        .col-6{
            padding: 0;
        }
    }
    .detail-content{
        .row{
            margin-bottom: 2rem;
            margin-left: 4rem;
        }
    }
    .publish-button{
        text-align: right;
    }

    .link-button{
        text-align: right;
        .btn.btn-light{
            border: darkgray 1px solid;
        }
    }
}

.campaign-creator {
    display: block;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    font-weight: bold;
}
.campaign-creator-input {
    width: 33%;
    text-align:center;
    align-items: center;
    display: inline-block;
}
.campaign-input-label {
    width: 100%;
    font-weight: bold;
    margin-top: 5%;
}

.campaign-input-label-step-2 {
    width: 100%;
    font-weight: bold;
    margin-top: 5%;
    margin-bottom: 10px;
}

.product-target{
    width: 80%;
    height: 100%;
    margin: 2rem auto;
    .title{
        margin: 0.5rem auto;
        text-align: center;
    }

    .link-button{
        text-align: right;
        .btn{
            padding:.3rem 2.5rem;
            margin: 1rem;
        }
        .btn.btn-light{
            border: darkgray 1px solid;
        }
    }
}
// integration card
.integration-card{
    width: 22rem;
    background-color: white;
    color: #20267A;
    .connected{
        color:#008000;
        font-weight: 300;
        font-size:1em;
        text-align: right;
    }
    .disconnected{
        color:gray;
        font-weight: 300;
        font-size:1em;
        text-align: right;
    }
    .social-image{
        color:#96bf48;
        margin: -5px 0 5px 5px;
        font-size: 45px;
    }
    .card-title{
        font-weight: bold;
    }
    .button-row{
        display: flex;
        justify-content: flex-end;
        padding: 1em;
    }
    .connect-button{
        padding:2px 25px;
    }
    .shop-name-input{
        border: 1px solid gray;
        border-right: 0px;
        outline: none;
        width: 113px;
        border-bottom-left-radius: calc(0.80rem - 1px);
        border-top-left-radius:calc(0.80rem - 1px);
        padding-left: 7px;
    }
    .shop-name-domain{
        text-align: right;
        width: 140px;
        border: 1px solid gray;
        border-left: 0px;
        margin: 0 0 0 -7px;
        background: white;
        border-bottom-right-radius: calc(0.80rem - 1px);
        border-top-right-radius:calc(0.80rem - 1px);
        padding-right: 5px;
    }
}
.instructions {
    border: 1px solid grey;
    background: #F5F3FF;
    width: 75rem;
    height: 10.5em;
    margin-top: 20px;
    margin-bottom: 0px;
    padding: 12px;
    border-radius:calc(0.30rem - 1px);
    .subtext {
        font-size: medium;
    }
    .steps {
        font-size: small;
    }
}

.footer {
  margin-top: 0rem;
  padding: 0rem;
  background-color: #F5F3FF;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 67px;
  display: flex;
  text-align: center;
  align-items: center;
  .create-campaign-progress {
    text-align: center;
    align-items: center;
    display:flex;
      margin-top: 8px;
      margin-bottom:8px;
      color: #808080;
      margin-left: auto;
    margin-right: auto;
  }
  .progress-element {
      text-align: center;
    padding: 8px;
  }
  .progress-element-active {
    text-align: center;
  padding: 8px;
  color: #000000;
}
}

.sign-up {
    margin-top: 2rem;
    .helper-text {
        text-align: center;
    }
    .form{
        margin-top: 2rem;
    }
}

.loading {
     text-align: center;
     .spinner {
         color: #FF0097;
     }
}

.editorTopNav{
    height: auto;
    width: 100%;
    background-image: linear-gradient(to right, #472EB4 , #D903FF);
    padding-right:0px !important;
    padding-left: 0px !important;
    color: white;
    font-weight: bold;
    display: flex;
    margin:0px;
}

.editorTitle {
    padding-left: 20px;
    font-weight: bold;
}
.editorPublishSave {
    display: flex;
    align-items: center;
    .text {
        margin: 5px;
        font-weight: lighter;
        font-style: italic;
    }
    .buttons {
        margin: 0px 5px 0px 5px;
    }
}
.designContainer {
    height: 100%;
    margin: 0px;
    display: flex;
    margin: 0px;
    padding: 0px;
}

.editorSideBar {
    background-color: #353e44;
    width: 35%;
    height: 100vh;
    color: white;
    padding: 15px 30px 0px 30px;
    overflow: scroll;
}

.editorDesignRenderer {
    width: 65%;
    padding: 15px 30px 0px 30px;
    height: auto;
    background-image: url("https://because-logo-assets.s3.us-east-2.amazonaws.com/editor-background-pattern.png") !important;
    background-repeat: repeat !important;
    align-items: center;
    display: flex;
    //flex-wrap: wrap;
    position: relative;
    justify-content: center;
}

.editorFormSideBySide {
    display: flex;
}

.formMarginForRightMostElem {
    width: 50%;
}
.formMarginForLeftMostElem {
    width: 50%;
    margin-right: 15px;
}
#font-picker{
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}
.bannerRenderer {
    margin: auto;
    position: absolute;
    width: auto;
    height: auto;
    top: 30%;
    margin-top: 0px;
    margin-bottom: 0px;
    //padding-bottom: 30%;
    margin-right: auto;
    margin-left: auto;
    
    
}

.resourceList {
    width: 100%;
    height: 100%;
    align-items: center;
    display: inline-block;
}

.resourceBox {
    margin-right:auto;
    margin-left:auto;
    margin-bottom: 15px;
    //border-style: solid;
    //border-color: #20267a;
    width: 60%;
    height: 30%;
    display:flex;
    align-items: center;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 5px #ebe8e8;
}

.textInResourceBoxContainer {
    height:90%;
    width: 55%;
    padding: 10px;
    margin: 0 auto;
    position: relative;
    font-size:0.9vw;
}
.resourceBoxHeader {
    font-size: 20px;
    font-weight: bold;
    font-family: 'Poppins';
    font-size:1.3vw;
}

.resourceBoxText {
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.resourceBoxExternalLink {
    left: 0;
    display: flex;
    padding-top: 10px;
    margin-top: 10px;
}

.reportingTitleBar {
    width: 80%;
    margin: auto;
    display: flex;
    padding-top: 10px;
    padding-bottom: 20px;
}
.reportingPageTitle {
    margin-top: 50px;
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: bold;
    width: 50%;
}
.reportingPageButtonContainer {
    align-self: right;
    text-align: right;
    width: 50%;
}
.toplineMetricsContainer {
    width: 80%;
    margin: auto;
    text-align: right;
    display: flex;
}
.bannerViewsTopline {
    margin-left: auto;
    padding-right: 15px;
    align-items: center !important;
    text-align: center;
}

.revenueInfluencedTopline {
    margin-right: 0;
    align-items: center !important;
    text-align: center;
}
.toplineViewsTitles {
    font-weight: bold;
    margin-bottom: 0px;
}

.toplineRevenueTitles {
    font-weight: bold;
    margin-bottom: 0px;
}
.toplineNumbers {
    font-size: 58px;
    font-weight: bold;
    color: #472eb4;
}
.reportingPageLine {
    width: 80%;
    height: 2px;
    margin: auto;
    background-color: #545454;
}

.reportEmptyImg {
    width: 7% ;
    height: 7%;
    margin-bottom: 10px;
}

.reportEmptyHeader {
    font-size: 24px;
    font-weight: bold;
}

.reportEmptyText {
    width:40%;
    text-align: center;
    margin-top: 10px;
    font-size: 17px;
    margin-bottom: 20px;
    margin-right: auto;
    margin-left: auto;
}

.reloginThroughShopifyContainer {
    width: 20%;
    height: 50%;
    margin-top: 10vw;
    margin-right: auto;
    margin-left: auto;
    display: block;
    background-color: #dcdcdc;
    align-items: center;
    border-color: #dcdcdc;
    border-style: solid;
    border-radius: 2px;
    border-width: 2px;
    box-shadow: 0 2px 12px -8px #344034;
}
.reloginThroughShopifyInner {
    width:100%;
    align-items: center !important;
    vertical-align:middle !important;
    text-align: center !important;
    display: flex;
}

.reloginTextContainer {
    width: 100%;
    align-items: center !important;
    vertical-align:middle !important;
    text-align: center !important;
}


.reloginText {
    color: #343434;
    font-size: 19px;
    font-weight: 700;
    margin: 0px 30px 0px 0px;
}

.reloginImage {
    color:#96bf48;
        margin: -5px 0 5px 5px;
        font-size: 45px;
}
.authenticateErrorContainer {
    width: 25%;
    height: 50%;
    margin-top: 10vw;
    margin-right: auto;
    margin-left: auto;
    display: block;
    text-align: center;
}
.authenticateErrorText {
    text-align: center;
    font-size: 20px;
    margin-top: 20px;
}

.nameEditingContainer{
    display: flex;
    align-items: center;
}

.editCampaignNameButton{
    display: flex;
    outline: none;
    border: none;
    color: white;
    background-color: transparent;
}

.saveCampaignNameButton{
    display: flex;
    outline: none;
    border: none;
    color: white;
    background-color: transparent;
}

.cancelCampaignNameButton{
    display: flex;
    outline: none;
    border: none;
    color: white;
    background-color: transparent;
}

.campaignNameEditBox{
    display: flex;
    outline: none;
    border-radius: 5px;
    height: 45px;
}

.noCampaignContainer{
    margin: 0 auto;
    text-align: center;
    align-items: center;
}

.installerAlertContainer{
    display: flex;
    width: auto;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    //margin-bottom: 40%;
    padding-bottom: 0px;
    bottom: 100%;
    font-size: 80%;
    position: sticky;
    background-color: #e8f4fc; 
}

.alertInfoIcon {
    padding-right: 10px;
}

.threeDotMenu {
    width: auto;
    height: auto;
    position: absolute;
    right: 10%;
    bottom: 6%;
}

.threeDotIcon {
    color: black; 
    width: auto;
    height: auto;
}

.deleteConformation {
    width: auto;
    height: auto ;
    position: absolute; 
}

.modalStyling {
    width: 30vw;
    max-width: 600px;
    height:auto;
}

.modalHeaderStyling {
    font-family: "Poppins", Roboto, "Helvetica Neue", Arial, sans-serif !important;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    border-bottom: 0 none;
    padding-bottom: 0;
    padding-top: 0;
}

.modalTitleStyling {
    order: 3;
    font-family: "Poppins", Roboto, "Helvetica Neue", Arial, sans-serif !important;
    padding-top: 0;
    font-size: 1vw;
}

.modalBodyStyling {
    font-size: .8vw;
}

.modalFooterStyling {
    //padding-top: 0;
    border-top: 0 none;
    display: flex;
    justify-content: left;
}

.modalHeaderGradient {
    display: flex;
    order: 0;
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    background-image: linear-gradient(to right, #472EB4 , #D903FF);
}

.confirmationModalDleteButton {
    height: 3rem;
    width: 20%;
    border-radius: 20rem;
    border: none;
    background-color:#ff0097;
    color: white;
    font-size: .9vw;
}

.confirmationModalCancelButton {
    height: 3rem;
    width: 20%;
    border-radius: 20rem;
    border: none;
    font-size: .9vw;
}

.iconRadioButtons {
    background: none !important;
    color: white;
    border: none !important;
    background-color: none !important;
    padding: 2% !important;
    margin: 0 !important;
    text-align: left !important;
    outline: none !important;
    box-shadow: none !important;
}

.iconPicker {
    background-color: white;
    border-radius: 5px;
    overflow: auto;
    max-width: 500px;
    max-height: 300px;
    padding: 0;
    border: 0;
}

.iconPickerButton {
    max-width: 100%;
    background-color: white;
    color: black;
    border: none;
}

.iconPickerItem {
    margin: auto;
    max-height: 60%;
    max-width: 60%;
    padding: 5%;
}

.iconPickerHolder {
    background-color: rgb(214, 211, 211);
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.iconPickerList {
    list-style: none;
    padding: 0;
    display:grid;
    grid-template-columns: repeat(3, 1fr);
}

.iconMenuItem {
    padding: 5px;
}

.productSelectorTable {
    //max-height: 500px;
    height: 60vh;
    overflow-y: auto;
    border-color: #dee2e6;
    border-style: solid;
    border-width: 1px;
    padding-bottom:10px;
}

.productSelectorPagination {
    position: relative;
    //margin: auto;
    margin-bottom: 70px;
    border-color: #dee2e6;
    border-style: solid;
    border-width: 1px;
    border-radius: 0rem;
    height: 8%;
}

.productSelectorButton {
    display: flex;
    align-items: center;
    margin: auto;
}

.productPageButton {
    background: none;
    border: none;
    padding: 5px;
}

.paginationNumbers{
    padding: 5px;
    margin-bottom: 0;
}

.productLoading{
    margin: auto;
}

.staticOrDynamicContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    width: auto;
    margin-top: 5%;
}

.staticOrDynamicButton {
    display: flex;
    margin: auto !important;
    height: 80% !important;
    width: 40% !important;
    border-style: solid !important;
    border-width: 2px !important;
    margin: 10px !important;
    padding: 10px !important;
    border-color: lightgray !important;
    border-radius: 5px !important;
    background-color: white !important;
    color: black !important;
    text-align: left !important;
}

.staticOrDynamicButtonClicked{
    display: flex;
    margin: auto !important;
    height: 80% !important;
    width: 40% !important;
    border-style: solid !important;
    border-width: 2px !important;
    margin: 10px !important;
    padding: 10px !important;
    border-color: darkgray !important;
    border-radius: 5px !important;
    background-color: lavender !important;
    color: black !important;
    text-align: left !important;
}

.staticOrDynamicButton:disabled {
    display: flex;
    margin: auto !important;
    height: 80% !important;
    width: 40% !important;
    border-style: solid !important;
    border-width: 2px !important;
    margin: 10px !important;
    padding: 10px !important;
    border-color: lightgray !important;
    border-radius: 5px !important;
    background-color: gainsboro !important;
    color: black !important;
    text-align: left !important;
    filter: grayscale(100%) !important;
}

.staticOrDynamicHeader{
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    text-align: center;
    padding: 30px;
    height: 80%;
    width: 100%;
}

.staticOrDynamicImage{
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 15px;
    height: 10%;
    width: 10%;
}

.staticOrDynamicInfo{
    display: flex;
    flex-direction: row;
}

.dynamicHeader{
    margin: auto;
    height: 80%;
    width: 100%;
    padding: 50px;
    text-align: center;
}

.targetingDropdown{
    background-color: #FFFFFF !important;
    border-color: #000000 !important;
    color: #000000 !important;
    font-weight: bold !important;
}

.productConditionsInput {
    padding-top: 100px;
    width: 100%;
}

.tagClear{
    background-color: lavender;
    padding: 5px;
    border: none;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100vh;
    font-size: .8rem;
}

.tagLabel {
    margin-right: 20px;
    display: flex;
    flex-direction: row;
}

.inputMenu {
    display: flex;
    flex-direction: row;
}

.tagButtons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: .5em;
    grid-column-gap: 1em;
    justify-items: start;
    align-items: center;
    padding-top: 5px;
}

.multiTagTesting {
    display: flex;
    flex-direction: row;
}

.tagInput {
    width: 40%;
}

.tagInputLabel {
    white-space: nowrap;
}

.inputAndTags {
    display:flex;
    flex-direction: column;
    //justify-content: flex-start;
}

.planContainer {
    display: block;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 60%;
}

.planWrapper {
    text-align: center;
    display: inline-block;
    width: 100%;
    height: 100%;
}

.planItemContainer {
    text-align: center !important;
    vertical-align: top !important;
    display: inline-block;
    width:20%;
    height: 55vh;
    min-height: 20%;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 5px #ebe8e8;
    margin: 25px;
}

.planItem {
    text-align: center !important;
    border-radius: 10px !important;
    width:100% !important;
    background-color: #FFFFFF!important;
    height: 100% !important;
    overflow: auto;
    //align-items:center !important;
}

.planButtonContainer {
    padding: 10px;
    display: inline-block;
}

.planButton {
    background-color: #FF0097;
    border-color: #FF0097 !important;
    border-radius: 10px !important;
}

.submitTag {
    background-color: #472EB4;
    border-color: #472EB4 !important;
    border-radius: 50px !important;
    max-height: 40px;
}

.submitTag:hover {
    background-color: #472EB4;
    border-color: #472EB4 !important;
}

.searchBar {
    align-self:flex-end;
}

.changelog {
    display: flex;
    flex-direction: row;
}

input[type='checkbox']:hover{
    cursor:pointer;
}

div[id^="font-picker"] .dropdown-button {
    background: white;
    color: black;
}

div[id^=font-picker] ul li button {
    background: white;
    color: black;
}

